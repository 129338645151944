import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Clients from './components/Clients';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Sitemap from './components/Sitemap';
import NotFound from './components/NotFound';
import NewYearPopup from './components/NewYearPopup';

function App() {
  const snowflakes = ["❅", "❆", "❉"];
  const currentMonth = new Date().getMonth(); // 0-11, where 11 is December
  const isDecember = currentMonth === 11;
  
  return (
    <Router>
      <div className="min-h-screen bg-white dark:bg-dark">
        {/* New Year Popup */}
        <NewYearPopup />
        
        {/* Snow Effect - Only shows in December */}
        {isDecember && (
          <div className="fixed inset-0 pointer-events-none z-50">
            {[...Array(10)].map((_, i) => (
              <div key={i} className="snowflake">
                {snowflakes[i % 3]}
              </div>
            ))}
          </div>
        )}
        
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <About />
              <Services />
              <Projects />
              <Clients />
              <Contact />
            </>
          } />
          <Route path="/sitemap" element={<Sitemap />} />
          {/* Catch all unmatched routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
