import React, { useState, useEffect } from 'react';

function NewYearPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const currentMonth = new Date().getMonth(); // 0-11, where 0 is January
  const isJanuary = currentMonth === 0;

  useEffect(() => {
    if (!isJanuary) return; // Only proceed if it's January

    // Show popup after a short delay
    const showTimer = setTimeout(() => setIsOpen(true), 1500);

    // Auto close after 12 seconds
    const closeTimer = setTimeout(() => {
      if (isOpen) {
        closePopup();
      }
    }, 13500); // 1.5s delay + 12s display = 13.5s total

    return () => {
      clearTimeout(showTimer);
      clearTimeout(closeTimer);
    };
  }, [isOpen, isJanuary]);

  const closePopup = () => {
    setIsClosing(true);
    setTimeout(() => setIsOpen(false), 500);
  };

  if (!isOpen || !isJanuary) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-500 ${
          isClosing ? 'opacity-0' : 'opacity-100'
        }`}
        onClick={closePopup}
      />

      {/* Popup Content */}
      <div 
        className={`relative bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 rounded-2xl p-8 max-w-lg w-full text-center transform transition-all duration-500 overflow-hidden ${
          isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
        }`}
      >
        {/* Close Button */}
        <button
          onClick={closePopup}
          className="absolute top-4 right-4 text-white/60 hover:text-white transition-colors z-50"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Celebration Animations */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {/* Fireworks */}
          {[...Array(20)].map((_, i) => (
            <div
              key={`firework-${i}`}
              className="absolute w-2 h-2 bg-white rounded-full animate-firework"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
              }}
            />
          ))}

          {/* Confetti */}
          {[...Array(30)].map((_, i) => (
            <div
              key={`confetti-${i}`}
              className="absolute w-3 h-3 animate-confetti"
              style={{
                left: `${Math.random() * 100}%`,
                backgroundColor: ['#FFD700', '#FF69B4', '#00FF00', '#FF4500', '#4169E1'][Math.floor(Math.random() * 5)],
                animationDelay: `${Math.random() * 3}s`,
                animationDuration: `${3 + Math.random() * 2}s`,
              }}
            />
          ))}

          {/* Sparkles */}
          {[...Array(15)].map((_, i) => (
            <div
              key={`sparkle-${i}`}
              className="absolute w-1 h-1 bg-yellow-300 rounded-full animate-sparkle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
                filter: 'blur(0.5px)',
              }}
            />
          ))}
        </div>

        {/* Content */}
        <div className="relative z-10">
          <h2 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-red-400 to-purple-400 animate-gradient-x mb-4">
            2025
          </h2>
          <h3 className="text-3xl font-bold text-white mb-6 animate-fade-in-up">
            Happy New Year!
          </h3>
          <p className="text-lg text-white/90 mb-8 animate-fade-in-up delay-200">
            Wishing you a year filled with new achievements and inspiring successes.
          </p>
          <button
            onClick={closePopup}
            className="px-8 py-3 bg-white/10 hover:bg-white/20 text-white rounded-lg transition-all transform hover:scale-105 animate-fade-in-up delay-300"
          >
            Let's Begin!
          </button>
        </div>

        {/* Decorative Elements */}
        <div className="absolute -top-10 -left-10 w-40 h-40 bg-blue-500/20 rounded-full blur-3xl animate-pulse-slow" />
        <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-purple-500/20 rounded-full blur-3xl animate-pulse-slow animation-delay-2000" />
      </div>
    </div>
  );
}

export default NewYearPopup; 