import React from 'react';

function Clients() {
  return (
    <section className="py-20 bg-gray-50 dark:bg-dark overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold gradient-text mb-4">
            Trusted by Industry Leaders
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Partnering with businesses worldwide to deliver exceptional digital solutions
          </p>
        </div>

        {/* Infinite Slider Container */}
        <div className="relative w-full overflow-hidden py-8">
          {/* Logo Track - Duplicated for seamless loop */}
          <div className="flex animate-slide-track">
            {/* First set of logos */}
            <div className="flex gap-16 min-w-max px-8">
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div key={`logo-${index}`} className="w-40 h-24 flex items-center justify-center">
                  <div className="w-full h-16 bg-gray-200/50 dark:bg-gray-800/50 rounded-xl flex items-center justify-center text-gray-500 dark:text-gray-400 backdrop-blur-sm border border-gray-200/20 dark:border-gray-700/20">
                    Logo {index}
                  </div>
                </div>
              ))}
            </div>
            {/* Duplicate set for seamless loop */}
            <div className="flex gap-16 min-w-max px-8">
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div key={`logo-dup-${index}`} className="w-40 h-24 flex items-center justify-center">
                  <div className="w-full h-16 bg-gray-200/50 dark:bg-gray-800/50 rounded-xl flex items-center justify-center text-gray-500 dark:text-gray-400 backdrop-blur-sm border border-gray-200/20 dark:border-gray-700/20">
                    Logo {index}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Gradient Overlays */}
          <div className="absolute top-0 left-0 w-32 h-full bg-gradient-to-r from-gray-50 dark:from-dark to-transparent z-10"></div>
          <div className="absolute top-0 right-0 w-32 h-full bg-gradient-to-l from-gray-50 dark:from-dark to-transparent z-10"></div>
        </div>

        {/* ERP Login CTA */}
        <div className="mt-16 text-center">
          <div className="inline-block p-0.5 rounded-xl bg-gradient-to-r from-primary to-secondary">
            <a
              href="https://erp.olexto.com"
              target="_blank"
              rel="noopener noreferrer"
              className="block px-8 py-4 rounded-lg bg-gray-50 dark:bg-dark hover:bg-transparent dark:hover:bg-transparent text-gray-900 dark:text-white hover:text-white transition-all duration-300"
            >
              <span className="flex items-center justify-center gap-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                </svg>
                Client Portal Login
              </span>
            </a>
          </div>
          <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
            Access your project dashboard and resources
          </p>
        </div>
      </div>
    </section>
  );
}

export default Clients; 