import React, { useState, useEffect } from 'react';

function Hero() {
  const [currentHeading, setCurrentHeading] = useState(0);
  const headings = [
    {
      title: "Web",
      subtitle: "Development",
      highlight: "Creative"
    },
    {
      title: "Digital",
      subtitle: "Solutions",
      highlight: "Innovative"
    },
    {
      title: "Tech",
      subtitle: "Excellence",
      highlight: "Professional"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeading((prev) => (prev + 1) % headings.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [headings.length]);

  return (
    <section id="hero" className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gray-50 dark:bg-[#0B1120] text-gray-900 dark:text-white">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-50 via-blue-50 to-gray-50 dark:from-[#0B1120] dark:via-[#122454] dark:to-[#0B1120]" />
        
        {/* Radial gradient overlay */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.1),transparent_50%)] dark:bg-[radial-gradient(circle_at_center,rgba(30,64,175,0.15),transparent_50%)]" />
        
        {/* Animated Gradient Orbs */}
        <div className="absolute top-0 -left-40 w-[1000px] h-[1000px] bg-gradient-to-br from-blue-400/10 to-blue-500/10 dark:from-[#1E40AF]/20 dark:to-[#3B82F6]/20 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-float" />
        <div className="absolute -bottom-40 -right-40 w-[1000px] h-[1000px] bg-gradient-to-br from-blue-500/10 to-blue-400/10 dark:from-[#3B82F6]/20 dark:to-[#1E40AF]/20 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-float animation-delay-2000" />

        {/* Sparkles */}
        <div className="absolute inset-0">
          {[...Array(15)].map((_, i) => (
            <div
              key={i}
              className={`absolute w-1 h-1 bg-blue-400 dark:bg-blue-300 rounded-full animate-sparkle delay-${(i % 5) + 1}`}
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                opacity: 0.6,
              }}
            />
          ))}
        </div>

        {/* Floating Elements */}
        <div className="absolute top-20 left-[20%] w-40 h-40 bg-gradient-to-br from-blue-400/5 to-blue-500/5 dark:from-[#1E40AF]/10 dark:to-[#3B82F6]/10 rounded-full animate-drift backdrop-blur-sm border border-blue-200/10 dark:border-blue-500/10" />
        <div className="absolute top-[60%] right-[15%] w-32 h-32 bg-gradient-to-bl from-blue-500/5 to-blue-400/5 dark:from-[#3B82F6]/10 dark:to-[#1E40AF]/10 rounded-full animate-drift delay-2 backdrop-blur-sm border border-blue-200/10 dark:border-blue-500/10" />
        <div className="absolute bottom-32 left-[40%] w-48 h-48 bg-gradient-to-tr from-blue-400/5 to-blue-500/5 dark:from-[#1E40AF]/10 dark:to-[#3B82F6]/10 rounded-full animate-drift delay-4 backdrop-blur-sm border border-blue-200/10 dark:border-blue-500/10" />

        {/* Grid Pattern */}
        <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.03)_1px,transparent_1px),linear-gradient(to_right,rgba(59,130,246,0.03)_1px,transparent_1px)] dark:bg-[linear-gradient(rgba(30,64,175,0.05)_1px,transparent_1px),linear-gradient(to_right,rgba(30,64,175,0.05)_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_at_center,white,transparent_70%)]" />
      </div>

      {/* Main Content Container */}
      <div className="relative w-full max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 py-20">
        <div className="flex flex-col items-center text-center">
          {/* Dynamic Headings */}
          <div className="relative h-[200px] sm:h-[240px] lg:h-[280px] w-full mb-12">
            {headings.map((heading, index) => (
              <div
                key={index}
                className={`absolute w-full transition-all duration-700 ${
                  index === currentHeading
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-8'
                }`}
              >
                <div className="inline-block mb-6">
                  <span className="inline-flex items-center px-4 py-1.5 text-sm font-medium text-blue-600 dark:text-blue-300 rounded-full bg-blue-100/80 dark:bg-blue-500/10 border border-blue-200/50 dark:border-blue-400/20 backdrop-blur-sm">
                    {heading.highlight}
                  </span>
                </div>
                
                <h1 className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl font-bold tracking-tight leading-[1.1]">
                  <span className="block text-gray-900 dark:text-white mb-4 drop-shadow-2xl">
                    {heading.title}
                  </span>
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 dark:from-blue-300 dark:via-blue-400 dark:to-blue-500">
                    {heading.subtitle}
                  </span>
                </h1>
              </div>
            ))}
          </div>

          {/* Description */}
          <p className="text-xl text-gray-600 dark:text-blue-100/80 max-w-2xl leading-relaxed mb-16 drop-shadow-lg">
            olexto Digital Solutions (Pvt) Ltd - Transform your digital presence with our innovative solutions. 
            We create exceptional experiences that drive growth and engagement.
          </p>

          {/* CTA Section */}
          <div className="flex flex-wrap justify-center gap-6 mb-20">
            <a
              href="#contact"
              className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg shadow-[0_0_20px_rgba(59,130,246,0.3)] hover:shadow-[0_0_25px_rgba(59,130,246,0.4)] transform hover:scale-[1.02] transition-all"
            >
              Start Project
              <svg className="ml-3 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </a>
            <a
              href="#projects"
              className="inline-flex items-center px-8 py-4 text-lg font-medium text-blue-600 dark:text-blue-300 border-2 border-blue-200/50 dark:border-blue-500/30 rounded-lg hover:bg-blue-50 dark:hover:bg-blue-500/10 backdrop-blur-sm transform hover:scale-[1.02] transition-all"
            >
              View Portfolio
            </a>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-3 gap-16 pt-16 border-t border-blue-200/30 dark:border-blue-500/20 max-w-4xl mx-auto w-full">
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 dark:text-blue-300 mb-2">12+</div>
              <div className="text-sm text-gray-600 dark:text-blue-200/70">Years Experience</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 dark:text-blue-300 mb-2">160+</div>
              <div className="text-sm text-gray-600 dark:text-blue-200/70">Projects Completed</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-600 dark:text-blue-300 mb-2">80+</div>
              <div className="text-sm text-gray-600 dark:text-blue-200/70">Global Clients</div>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2">
        <div className="animate-bounce">
          <svg className="w-6 h-6 text-blue-500 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default Hero; 