import React from 'react';

function Sitemap() {
  const siteStructure = {
    'Main Pages': [
      { name: 'Home', path: '/' },
      { name: 'About Us', path: '#about' },
      { name: 'Services', path: '#services' },
      { name: 'Projects', path: '#projects' },
      { name: 'Contact', path: '#contact' }
    ],
    'Services': [
      { name: 'Web Development', path: '#services' },
      { name: 'Mobile Development', path: '#services' },
      { name: 'Cloud Solutions', path: '#services' },
      { name: 'Digital Strategy', path: '#services' }
    ],
    'Resources': [
      { name: 'Blog', path: '#blog' },
      { name: 'Case Studies', path: '#case-studies' },
      { name: 'Documentation', path: '#docs' },
      { name: 'FAQs', path: '#faqs' }
    ],
    'Company': [
      { name: 'About Us', path: '#about' },
      { name: 'Our Projects', path: '#projects' },
      { name: 'Clients', path: '#clients' },
      { name: 'Careers', path: '#careers' }
    ],
    'Legal': [
      { name: 'Privacy Policy', path: '#privacy' },
      { name: 'Terms of Service', path: '#terms' },
      { name: 'Cookie Policy', path: '#cookies' },
      { name: 'GDPR Compliance', path: '#gdpr' }
    ],
    'Client Area': [
      { name: 'Client Portal', path: 'https://erp.olexto.com', external: true },
      { name: 'Support', path: '#support' },
      { name: 'Knowledge Base', path: '#kb' }
    ]
  };

  return (
    <section className="py-20 bg-gray-50 dark:bg-dark">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold gradient-text mb-4">Sitemap</h1>
          <p className="text-gray-600 dark:text-gray-400">
            Complete overview of our website structure
          </p>
        </div>

        {/* Sitemap Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {Object.entries(siteStructure).map(([category, links]) => (
            <div key={category} className="space-y-6">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {category}
              </h2>
              <ul className="space-y-3">
                {links.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.path}
                      target={link.external ? "_blank" : undefined}
                      rel={link.external ? "noopener noreferrer" : undefined}
                      className="group flex items-center text-gray-600 dark:text-gray-400 hover:text-primary dark:hover:text-primary transition-colors"
                    >
                      <span className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        →
                      </span>
                      {link.name}
                      {link.external && (
                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Sitemap; 